import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
@Injectable()
export class UserService {
  getproshows: any;

  constructor(private http: HttpClient) {}

  getCards(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/cards`)
      .pipe(map((user) => user));
  }

  getProshow(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/proshows`)
      .pipe(map((user) => user));
  }

  getMerchandise(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/tshirt/view`
      )
      .pipe(map((user) => user));
  }

  getshows(page): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/proshow-events?page=` +
          page
      )
      .pipe(map((user) => user));
  }

  myeventlist(page): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events-schedule/events/my-events?page=` +
          page
      )
      .pipe(map((user) => user));
  }

  myeventresult(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/result/my-event?page=` +
          page +
          "&search=" +
          search
      )
      .pipe(map((user) => user));
  }
  alleventresult(page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/result/all-event?page=` +
          page +
          "&search=" +
          search
      )
      .pipe(map((user) => user));
  }

  myproshowevent(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events-schedule/my-proshow-events`
      )
      .pipe(map((user) => user));
  }

  tshirtevent(): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events-schedule/my-merch`
      )
      .pipe(map((user) => user));
  }

  getcombocard(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/combos`)
      .pipe(map((user) => user));
  }

  geteventdetails(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events/view/` + id
      )
      .pipe(map((user) => user));
  }

  getteamdetails(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/teams/members?team_id=` +
          id
      )
      .pipe(map((user) => user));
  }

  geteventbycard(id, page, search): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events?card_id=` +
          id +
          "&page=" +
          page +
          "&search=" +
          search
      )
      .pipe(map((user) => user));
  }

  getprofile(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}${environment.apiPrefix}/users/profile`)
      .pipe(map((user) => user));
  }

  createteam(id) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/teams/create`,
        { event_id: id }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  deleteteam(id) {
    return this.http
      .delete<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/teams/delete/` + id
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  makepayment(id) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/cards/purchase`,
        { card_id: id }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  makecombopayment(id, size: any) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/combos/purchase`,
        { combo_id: id, size_qty: JSON.stringify(size) }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  showpayment(id) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/proshows/purchase`,
        { proshow_id: id }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  tshirtpayment(id, size) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/tshirt/purchase`,
        { tshirt_id: id, size_qty: JSON.stringify(size) }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  eventpayment(id) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events/purchase`,
        { event_id: id }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getcardcheckout(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/cards/checkout?card_id=` +
          id
      )
      .pipe(map((user) => user));
  }

  getcombocardcheckout(id, size): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/combos/checkout?combo_id=` +
          id +
          "&size_qty=" +
          JSON.stringify(size)
      )
      .pipe(map((user) => user));
  }

  geteventcheckout(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events/checkout?event_id=` +
          id
      )
      .pipe(map((user) => user));
  }

  getproshowcheckout(id): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/proshows/checkout?proshow_id=` +
          id
      )
      .pipe(map((user) => user));
  }

  gettshirtcheckout(id, size): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/tshirt/checkout?tshirt_id=` +
          id +
          "&size_qty=" +
          JSON.stringify(size)
      )
      .pipe(map((user) => user));
  }

  eventjoin(id) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events/join`,
        { event_id: id }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  eventleave(id) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/events/leave`,
        { event_id: id }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  teamjoin(id, teamid) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/teams/join`,
        { event_id: id, team_id: teamid }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  updateprofile(name, email) {
    return this.http
      .put<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/profile/update`,
        { full_name: name, email: email }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  leaveteam(id, teamid) {
    return this.http
      .post<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/teams/leave`,
        { event_id: id, team_id: teamid }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  merchandiseInvoicePdf(tshirt_id: any, booking_id: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}${environment.apiPrefix}/users/receipt/merch?tshirt_id=` +
          tshirt_id +
          `&booking_id=` +
          booking_id,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((response) => {
          const blob = new Blob([response], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `team.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          return response;
        })
      );
  }
}
